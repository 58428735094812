import css from './LocaleMenu.module.css';
import { useIntl } from '../../util/reactIntl';
import {useLocation, useHistory} from 'react-router-dom'
import ReactFlagsSelect from "react-flags-select";

const LocaleMenu = ({onSetLocale}) => {
  const  {formatMessage, locale} = useIntl();
  const { pathname } = useLocation();
  const history = useHistory();
  const en = formatMessage({ id: 'LocaleMenu.english' });
  const fr = formatMessage({ id: 'LocaleMenu.french' });
  const es = formatMessage({ id: 'LocaleMenu.spanish' });
  const de = formatMessage({ id: 'LocaleMenu.german' });

  const updateLocale = (newLocale, pathname, history) => {
    newLocale = newLocale === 'GB' ? 'EN'.toLowerCase(): newLocale.toLowerCase()
    onSetLocale(newLocale)
    history.push(pathname.replace(locale, newLocale))
  }

  const selected = locale === 'en' ? 'gb' : locale;

  return (
        <ReactFlagsSelect
          countries={["GB", "FR", "DE", "ES"]}
          selected={selected.toUpperCase()}
          onSelect={(code) => updateLocale(code, pathname, history)}
          showSelectedLabel={false}
          showOptionLabel={false}
          className={css.root}
          optionsSize={20}
          fullWidth={false}
          selectButtonClassName="menu-flags-button"
        />
  )
}

export default LocaleMenu
